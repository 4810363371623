import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = ["content"];

  static values = {
    toggled: { type: Boolean, default: false },
    useClickOutside: Boolean
  }

  connect() {
    if (this.useClickOutsideValue) {
      useClickOutside(this);
    }
    if (!this.toggledValue) {
      this.contentTarget.classList.add("hidden");
    }
  }

  toggle() {
    this.contentTarget.classList.toggle("hidden");
  }

  hide() {
    this.contentTarget.classList.add("hidden");
  }

  // TODO check bug ?
  clickOutside(event) {
    // event.preventDefault();
    this.contentTarget.classList.add("hidden");
  }

}