import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["existingButton", "newButton", "existingFields", "newFields", "typeFieldValue"];

  static values = {
    type: String,
  }

  connect() {
    if (this.typeValue === "existing") {
      this.selectExisting();
    } else {
      this.selectNew();
    }
  }

  selectExisting() {
    this.typeValue = "existing";
    this.existingButtonTarget.classList.add("bg-primary", "text-white", "font-medium");
    this.existingButtonTarget.classList.remove("bg-secondary-light", "text-gray-500", "text-black");
    this.newButtonTarget.classList.remove("bg-primary","text-white", "font-medium");
    this.newButtonTarget.classList.add("bg-secondary-light", "text-black");

    this.existingFieldsTarget.classList.remove("hidden");
    this.newFieldsTarget.classList.add("hidden");

    this.typeFieldValueTarget.value = "existing";
  }

  selectNew() {
    this.typeValue = "new";
    this.newButtonTarget.classList.add("bg-primary", "text-white", "font-medium");
    this.newButtonTarget.classList.remove("bg-secondary-light", "text-gray-500", "text-black");
    this.existingButtonTarget.classList.remove("bg-primary", "text-white", "font-medium");
    this.existingButtonTarget.classList.add("bg-secondary-light", "text-black");

    this.existingFieldsTarget.classList.add("hidden");
    this.newFieldsTarget.classList.remove("hidden");

    this.typeFieldValueTarget.value = "new";
  }

}