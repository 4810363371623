import { Controller } from "@hotwired/stimulus";

const optionSelector = "[role='option']:not([aria-disabled])"

export default class extends Controller {
  static targets = ["searchInput", "results", "selectedItems"]

  static values = {
    ready: Boolean,
    submitOnEnter: Boolean,
    url: String,
    minLength: Number,
    delay: { type: Number, default: 300 },
    queryParam: { type: String, default: "q" },
    selectedItems: Array,
    inputId: String,
    includeJuniors: { type: Boolean, default: false },
  }

  connect() {
    this.resultsTarget.addEventListener("click", this.onResultsClick)
    for (let i = 0; i < this.selectedItemsValue.length; i++) {
      this.selectedItemsTarget
        .insertAdjacentHTML(
          "beforeend",
          `
          <div class="px-3 py-2 flex rounded bg-gray-200" data-id="${this.selectedItemsValue[i].id}" data-cy="selected-user">
            <div class="w-8 h-8 flex justify-center content-center rounded overflow-hidden mr-2">
              <img class="object-cover object-center w-full h-full" src="${this.selectedItemsValue[i].image}">
            </div>
            <span class="flex items-center flex-1">${this.selectedItemsValue[i].label}</span>
            <div class="w-8 h-8 flex justify-center items-center rounded cursor-pointer hover:bg-gray-300" data-id="${this.selectedItemsValue[i].id}" data-action="click->autocomplete-multi-search#delete" data-cy="delete-user">
              <i class="fas fa-times"></i>
            </div>
          </div>
        `);
    }
  }

  onResultsClick = (event) => {
    if (!(event.target instanceof Element)) return
    const selected = event.target.closest(optionSelector)
    if (selected) this.select(selected)
  }

  autocomplete(event) {
    const searchedTerms = this.searchInputTarget.value;
    if (searchedTerms && searchedTerms.length >= this.minLengthValue) {
      this.fetchResults(searchedTerms);
    } else {
    }
  }

  select(selected) {
    if (selected.getAttribute("aria-disabled") === "true") return

    if (selected instanceof HTMLAnchorElement) {
      selected.click()
      this.close()
      return
    }

    const searchedItemId = selected.getAttribute("data-id");
    const searchedItemLabel = selected.getAttribute("data-label");
    const searchedItemImage = selected.getAttribute("data-image");
    if (searchedItemId !== null && searchedItemId != undefined) {
      this.hideResults();
      this.searchInputTarget.value = "";
      this.resultsTarget.innerHTML = "";
      let alreadySelected = false;
      let selectInput = this.getSelectInput();
      let existingOptionIndex = this.findOptionIndex(searchedItemId);

      if (existingOptionIndex !== undefined) {
        if (selectInput.options[existingOptionIndex].selected == true) {
          alreadySelected = true;
        } else {
          selectInput.options[existingOptionIndex].selected = true;
        }
      } else {
        let newOption = new Option(searchedItemId,searchedItemId);
        selectInput.add(newOption, undefined);
        let optionIndex = this.findOptionIndex(searchedItemId);
        selectInput.options[optionIndex].selected = true;
      }
      if (alreadySelected == false) {
        this.selectedItemsTarget
          .insertAdjacentHTML(
            'afterbegin',
            `
          <div class="px-3 py-2 flex rounded-lg bg-gray-200" data-id="${searchedItemId}" data-cy="selected-user">
            <div class="w-8 h-8 flex justify-center content-center rounded overflow-hidden mr-2">
              <img class="object-cover object-center w-full h-full" src="${searchedItemImage}">
            </div>
            <span class="flex items-center flex-1">${searchedItemLabel}</span>
            <div class="w-8 h-8 flex justify-center items-center rounded cursor-pointer hover:bg-gray-300" data-id="${searchedItemId}" data-action="click->autocomplete-multi-search#delete" data-cy="delete-user">
              <i class="fas fa-times"></i>
            </div>
          </div>
        `);
      }
    }
  }

  getSelectInput() {
    const inputId = (this.inputIdValue !== null && this.inputIdValue !== undefined && this.inputIdValue !== '') ? this.inputIdValue : 'profile-ids-input';
    return document.getElementById(inputId);
  }

  findOptionIndex(searchedId) {
    let selectInput = this.getSelectInput();
    const length = selectInput.options.length;
    for (let i = 0; i < length; i++) {
      if (selectInput.options[i].value == searchedId) {
        return [i]
      }
    }
    return undefined;
  }

  delete(event) {
    const deletedItemId = event.currentTarget.dataset.id;
    if (deletedItemId !== null && deletedItemId != undefined) {
      let optionIndex = this.findOptionIndex(deletedItemId);
      let selectInput = this.getSelectInput();
      selectInput.options[optionIndex].selected = false;
      this.selectedItemsTarget.querySelector(`[data-id="${deletedItemId}"]`).remove();
    }
  }

  fetchResults = async (query) => {
    if (!this.hasUrlValue) return

    const url = this.buildURL(query)
    try {
      this.element.dispatchEvent(new CustomEvent("loadstart"))
      const html = await this.doFetch(url)
      this.replaceResults(html)
      this.element.dispatchEvent(new CustomEvent("load"))
      this.element.dispatchEvent(new CustomEvent("loadend"))
    } catch(error) {
      this.element.dispatchEvent(new CustomEvent("error"))
      this.element.dispatchEvent(new CustomEvent("loadend"))
      throw error
    }
  }

  replaceResults(html) {
    if (html !== "") {
      this.resultsTarget.innerHTML = html;
      this.showResults();
    } else {
      this.hideResults();
    }
  }

  buildURL(query) {
    const url = new URL(this.urlValue, window.location.origin);
    const params = new URLSearchParams(url.search.slice(1));
    params.append(this.queryParamValue, query);
    url.search = params.toString();

    console.log("this.includeJuniorsValue", this.includeJuniorsValue);

    if (this.includeJuniorsValue) {
      url.searchParams.append('include_juniors', "true");
    }

    return url.toString();
  }

  doFetch = async (url) => {
    const response = await fetch(url, this.optionsForFetch())

    if (!response.ok) {
      throw new Error(`Server responded with status ${response.status}`)
    }

    const html = await response.text()
    return html
  }

  optionsForFetch() {
    return { headers: { "X-Requested-With": "XMLHttpRequest" } }
  }

  showResults() {
    this.resultsTarget.classList.remove("hidden");
  }

  hideResults() {
    this.resultsTarget.classList.add("hidden");
  }

}